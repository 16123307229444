export const POSTS_PER_REQUEST_LIMIT = 10;

// For performing actions after toast is shown
export const DEFAULT_TOAST_DURATION = 2000;

// List of countries
export const COUNTRIES = [
  "Ascension Island",
  "Andorra",
  "United Arab Emirates",
  "Afghanistan",
  "Antigua & Barbuda",
  "Anguilla",
  "Albania",
  "Armenia",
  "Angola",
  "Antarctica",
  "Argentina",
  "American Samoa",
  "Austria",
  "Australia",
  "Aruba",
  "Åland Islands",
  "Azerbaijan",
  "Bosnia & Herzegovina",
  "Barbados",
  "Bangladesh",
  "Belgium",
  "Burkina Faso",
  "Bulgaria",
  "Bahrain",
  "Burundi",
  "Benin",
  "St. Barthélemy",
  "Bermuda",
  "Brunei",
  "Bolivia",
  "Caribbean Netherlands",
  "Brazil",
  "Bahamas",
  "Bhutan",
  "Bouvet Island",
  "Botswana",
  "Belarus",
  "Belize",
  "Canada",
  "Cocos (Keeling) Islands",
  "Congo - Kinshasa",
  "Central African Republic",
  "Congo - Brazzaville",
  "Switzerland",
  "Côte d’Ivoire",
  "Cook Islands",
  "Chile",
  "Cameroon",
  "China",
  "Colombia",
  "Clipperton Island",
  "Costa Rica",
  "Cuba",
  "Cape Verde",
  "Curaçao",
  "Christmas Island",
  "Cyprus",
  "Czechia",
  "Germany",
  "Diego Garcia",
  "Djibouti",
  "Denmark",
  "Dominica",
  "Dominican Republic",
  "Algeria",
  "Ceuta & Melilla",
  "Ecuador",
  "Estonia",
  "Egypt",
  "Western Sahara",
  "Eritrea",
  "Spain",
  "Ethiopia",
  "European Union",
  "Finland",
  "Fiji",
  "Falkland Islands",
  "Micronesia",
  "Faroe Islands",
  "France",
  "Gabon",
  "United Kingdom",
  "Grenada",
  "Georgia",
  "French Guiana",
  "Guernsey",
  "Ghana",
  "Gibraltar",
  "Greenland",
  "Gambia",
  "Guinea",
  "Guadeloupe",
  "Equatorial Guinea",
  "Greece",
  "South Georgia & South Sandwich Islands",
  "Guatemala",
  "Guam",
  "Guinea-Bissau",
  "Guyana",
  "Hong Kong SAR China",
  "Heard & McDonald Islands",
  "Honduras",
  "Croatia",
  "Haiti",
  "Hungary",
  "Canary Islands",
  "Indonesia",
  "Ireland",
  "Israel",
  "Isle of Man",
  "India",
  "British Indian Ocean Territory",
  "Iraq",
  "Iran",
  "Iceland",
  "Italy",
  "Jersey",
  "Jamaica",
  "Jordan",
  "Japan",
  "Kenya",
  "Kyrgyzstan",
  "Cambodia",
  "Kiribati",
  "Comoros",
  "St. Kitts & Nevis",
  "North Korea",
  "South Korea",
  "Kuwait",
  "Cayman Islands",
  "Kazakhstan",
  "Laos",
  "Lebanon",
  "St. Lucia",
  "Liechtenstein",
  "Sri Lanka",
  "Liberia",
  "Lesotho",
  "Lithuania",
  "Luxembourg",
  "Latvia",
  "Libya",
  "Morocco",
  "Monaco",
  "Moldova",
  "Montenegro",
  "St. Martin",
  "Madagascar",
  "Marshall Islands",
  "North Macedonia",
  "Mali",
  "Myanmar (Burma)",
  "Mongolia",
  "Macao Sar China",
  "Northern Mariana Islands",
  "Martinique",
  "Mauritania",
  "Montserrat",
  "Malta",
  "Mauritius",
  "Maldives",
  "Malawi",
  "Mexico",
  "Malaysia",
  "Mozambique",
  "Namibia",
  "New Caledonia",
  "Niger",
  "Norfolk Island",
  "Nigeria",
  "Nicaragua",
  "Netherlands",
  "Norway",
  "Nepal",
  "Nauru",
  "Niue",
  "New Zealand",
  "Oman",
  "Panama",
  "Peru",
  "French Polynesia",
  "Papua New Guinea",
  "Philippines",
  "Pakistan",
  "Poland",
  "St. Pierre & Miquelon",
  "Pitcairn Islands",
  "Puerto Rico",
  "Palestinian Territories",
  "Portugal",
  "Palau",
  "Paraguay",
  "Qatar",
  "Réunion",
  "Romania",
  "Serbia",
  "Russia",
  "Rwanda",
  "Saudi Arabia",
  "Solomon Islands",
  "Seychelles",
  "Sudan",
  "Sweden",
  "Singapore",
  "St. Helena",
  "Slovenia",
  "Svalbard & Jan Mayen",
  "Slovakia",
  "Sierra Leone",
  "San Marino",
  "Senegal",
  "Somalia",
  "Suriname",
  "South Sudan",
  "São Tomé & Príncipe",
  "El Salvador",
  "Sint Maarten",
  "Syria",
  "Eswatini",
  "Tristan Da Cunha",
  "Turks & Caicos Islands",
  "Chad",
  "French Southern Territories",
  "Togo",
  "Thailand",
  "Tajikistan",
  "Tokelau",
  "Timor-Leste",
  "Turkmenistan",
  "Tunisia",
  "Tonga",
  "Turkey",
  "Trinidad & Tobago",
  "Tuvalu",
  "Taiwan",
  "Tanzania",
  "Ukraine",
  "Uganda",
  "U.S. Outlying Islands",
  "United Nations",
  "United States",
  "Uruguay",
  "Uzbekistan",
  "Vatican City",
  "St. Vincent & Grenadines",
  "Venezuela",
  "British Virgin Islands",
  "U.S. Virgin Islands",
  "Vietnam",
  "Vanuatu",
  "Wallis & Futuna",
  "Samoa",
  "Kosovo",
  "Yemen",
  "Mayotte",
  "South Africa",
  "Zambia",
  "Zimbabwe",
  "England",
  "Scotland",
  "Wales",
];

// List of countries and their emoji flag
export const COUNTRY_FLAGS = [
  { name: "Afghanistan", flag: "🇦🇫" },
  { name: "Åland Islands", flag: "🇦🇽" },
  { name: "Albania", flag: "🇦🇱" },
  { name: "Algeria", flag: "🇩🇿" },
  { name: "American Samoa", flag: "🇦🇸" },
  { name: "Andorra", flag: "🇦🇩" },
  { name: "Angola", flag: "🇦🇴" },
  { name: "Anguilla", flag: "🇦🇮" },
  { name: "Antarctica", flag: "🇦🇶" },
  { name: "Antigua & Barbuda", flag: "🇦🇬" },
  { name: "Argentina", flag: "🇦🇷" },
  { name: "Armenia", flag: "🇦🇲" },
  { name: "Aruba", flag: "🇦🇼" },
  { name: "Ascension Island", flag: "🇦🇨" },
  { name: "Australia", flag: "🇦🇺" },
  { name: "Austria", flag: "🇦🇹" },
  { name: "Azerbaijan", flag: "🇦🇿" },
  { name: "Bahamas", flag: "🇧🇸" },
  { name: "Bahrain", flag: "🇧🇭" },
  { name: "Bangladesh", flag: "🇧🇩" },
  { name: "Barbados", flag: "🇧🇧" },
  { name: "Belarus", flag: "🇧🇾" },
  { name: "Belgium", flag: "🇧🇪" },
  { name: "Belize", flag: "🇧🇿" },
  { name: "Benin", flag: "🇧🇯" },
  { name: "Bermuda", flag: "🇧🇲" },
  { name: "Bhutan", flag: "🇧🇹" },
  { name: "Bolivia", flag: "🇧🇴" },
  { name: "Bosnia & Herzegovina", flag: "🇧🇦" },
  { name: "Botswana", flag: "🇧🇼" },
  { name: "Bouvet Island", flag: "🇧🇻" },
  { name: "Brazil", flag: "🇧🇷" },
  { name: "British Indian Ocean Territory", flag: "🇮🇴" },
  { name: "British Virgin Islands", flag: "🇻🇬" },
  { name: "Brunei", flag: "🇧🇳" },
  { name: "Bulgaria", flag: "🇧🇬" },
  { name: "Burkina Faso", flag: "🇧🇫" },
  { name: "Burundi", flag: "🇧🇮" },
  { name: "Cambodia", flag: "🇰🇭" },
  { name: "Cameroon", flag: "🇨🇲" },
  { name: "Canada", flag: "🇨🇦" },
  { name: "Canary Islands", flag: "🇮🇨" },
  { name: "Cape Verde", flag: "🇨🇻" },
  { name: "Caribbean Netherlands", flag: "🇧🇶" },
  { name: "Cayman Islands", flag: "🇰🇾" },
  { name: "Central African Republic", flag: "🇨🇫" },
  { name: "Ceuta & Melilla", flag: "🇪🇦" },
  { name: "Chad", flag: "🇹🇩" },
  { name: "Chile", flag: "🇨🇱" },
  { name: "China", flag: "🇨🇳" },
  { name: "Christmas Island", flag: "🇨🇽" },
  { name: "Clipperton Island", flag: "🇨🇵" },
  { name: "Cocos (Keeling) Islands", flag: "🇨🇨" },
  { name: "Colombia", flag: "🇨🇴" },
  { name: "Comoros", flag: "🇰🇲" },
  { name: "Congo - Brazzaville", flag: "🇨🇬" },
  { name: "Congo - Kinshasa", flag: "🇨🇩" },
  { name: "Cook Islands", flag: "🇨🇰" },
  { name: "Costa Rica", flag: "🇨🇷" },
  { name: "Côte d'Ivoire", flag: "🇨🇮" },
  { name: "Croatia", flag: "🇭🇷" },
  { name: "Cuba", flag: "🇨🇺" },
  { name: "Curaçao", flag: "🇨🇼" },
  { name: "Cyprus", flag: "🇨🇾" },
  { name: "Czechia", flag: "🇨🇿" },
  { name: "Denmark", flag: "🇩🇰" },
  { name: "Diego Garcia", flag: "🇩🇬" },
  { name: "Djibouti", flag: "🇩🇯" },
  { name: "Dominica", flag: "🇩🇲" },
  { name: "Dominican Republic", flag: "🇩🇴" },
  { name: "Ecuador", flag: "🇪🇨" },
  { name: "Egypt", flag: "🇪🇬" },
  { name: "El Salvador", flag: "🇸🇻" },
  { name: "England", flag: "🏴󠁧󠁢󠁥󠁮󠁧󠁿" },
  { name: "Equatorial Guinea", flag: "🇬🇶" },
  { name: "Eritrea", flag: "🇪🇷" },
  { name: "Estonia", flag: "🇪🇪" },
  { name: "Eswatini", flag: "🇸🇿" },
  { name: "Ethiopia", flag: "🇪🇹" },
  { short_name: "EU", name: "European Union", flag: "🇪🇺" },
  { name: "Falkland Islands", flag: "🇫🇰" },
  { name: "Faroe Islands", flag: "🇫🇴" },
  { name: "Fiji", flag: "🇫🇯" },
  { name: "Finland", flag: "🇫🇮" },
  { name: "France", flag: "🇫🇷" },
  { name: "French Guiana", flag: "🇬🇫" },
  { name: "French Polynesia", flag: "🇵🇫" },
  { name: "French Southern Territories", flag: "🇹🇫" },
  { name: "Gabon", flag: "🇬🇦" },
  { name: "Gambia", flag: "🇬🇲" },
  { name: "Georgia", flag: "🇬🇪" },
  { name: "Germany", flag: "🇩🇪" },
  { name: "Ghana", flag: "🇬🇭" },
  { name: "Gibraltar", flag: "🇬🇮" },
  { name: "Greece", flag: "🇬🇷" },
  { name: "Greenland", flag: "🇬🇱" },
  { name: "Grenada", flag: "🇬🇩" },
  { name: "Guadeloupe", flag: "🇬🇵" },
  { name: "Guam", flag: "🇬🇺" },
  { name: "Guatemala", flag: "🇬🇹" },
  { name: "Guernsey", flag: "🇬🇬" },
  { name: "Guinea-Bissau", flag: "🇬🇼" },
  { name: "Guinea", flag: "🇬🇳" },
  { name: "Guyana", flag: "🇬🇾" },
  { name: "Haiti", flag: "🇭🇹" },
  { name: "Heard & McDonald Islands", flag: "🇭🇲" },
  { name: "Honduras", flag: "🇭🇳" },
  { name: "Hong Kong SAR China", flag: "🇭🇰" },
  { name: "Hungary", flag: "🇭🇺" },
  { name: "Iceland", flag: "🇮🇸" },
  { name: "India", flag: "🇮🇳" },
  { name: "Indonesia", flag: "🇮🇩" },
  { name: "Iran", flag: "🇮🇷" },
  { name: "Iraq", flag: "🇮🇶" },
  { name: "Ireland", flag: "🇮🇪" },
  { name: "Isle of Man", flag: "🇮🇲" },
  { name: "Israel", flag: "🇮🇱" },
  { name: "Italy", flag: "🇮🇹" },
  { name: "Jamaica", flag: "🇯🇲" },
  { name: "Japan", flag: "🇯🇵" },
  { name: "Jersey", flag: "🇯🇪" },
  { name: "Jordan", flag: "🇯🇴" },
  { name: "Kazakhstan", flag: "🇰🇿" },
  { name: "Kenya", flag: "🇰🇪" },
  { name: "Kiribati", flag: "🇰🇮" },
  { name: "Kosovo", flag: "🇽🇰" },
  { name: "Kuwait", flag: "🇰🇼" },
  { name: "Kyrgyzstan", flag: "🇰🇬" },
  { name: "Laos", flag: "🇱🇦" },
  { name: "Latvia", flag: "🇱🇻" },
  { name: "Lebanon", flag: "🇱🇧" },
  { name: "Lesotho", flag: "🇱🇸" },
  { name: "Liberia", flag: "🇱🇷" },
  { name: "Libya", flag: "🇱🇾" },
  { name: "Liechtenstein", flag: "🇱🇮" },
  { name: "Lithuania", flag: "🇱🇹" },
  { name: "Luxembourg", flag: "🇱🇺" },
  { name: "Macao Sar China", flag: "🇲🇴" },
  { name: "Madagascar", flag: "🇲🇬" },
  { name: "Malawi", flag: "🇲🇼" },
  { name: "Malaysia", flag: "🇲🇾" },
  { name: "Maldives", flag: "🇲🇻" },
  { name: "Mali", flag: "🇲🇱" },
  { name: "Malta", flag: "🇲🇹" },
  { name: "Marshall Islands", flag: "🇲🇭" },
  { name: "Martinique", flag: "🇲🇶" },
  { name: "Mauritania", flag: "🇲🇷" },
  { name: "Mauritius", flag: "🇲🇺" },
  { name: "Mayotte", flag: "🇾🇹" },
  { name: "Mexico", flag: "🇲🇽" },
  { name: "Micronesia", flag: "🇫🇲" },
  { name: "Moldova", flag: "🇲🇩" },
  { name: "Monaco", flag: "🇲🇨" },
  { name: "Mongolia", flag: "🇲🇳" },
  { name: "Montenegro", flag: "🇲🇪" },
  { name: "Montserrat", flag: "🇲🇸" },
  { name: "Morocco", flag: "🇲🇦" },
  { name: "Mozambique", flag: "🇲🇿" },
  { name: "Myanmar (Burma)", flag: "🇲🇲" },
  { name: "Namibia", flag: "🇳🇦" },
  { name: "Nauru", flag: "🇳🇷" },
  { name: "Nepal", flag: "🇳🇵" },
  { name: "Netherlands", flag: "🇳🇱" },
  { name: "New Caledonia", flag: "🇳🇨" },
  { name: "New Zealand", flag: "🇳🇿" },
  { name: "Nicaragua", flag: "🇳🇮" },
  { name: "Niger", flag: "🇳🇪" },
  { name: "Nigeria", flag: "🇳🇬" },
  { name: "Niue", flag: "🇳🇺" },
  { name: "Norfolk Island", flag: "🇳🇫" },
  { name: "North Korea", flag: "🇰🇵" },
  { name: "North Macedonia", flag: "🇲🇰" },
  { name: "Northern Mariana Islands", flag: "🇲🇵" },
  { name: "Norway", flag: "🇳🇴" },
  { name: "Oman", flag: "🇴🇲" },
  { name: "Pakistan", flag: "🇵🇰" },
  { name: "Palau", flag: "🇵🇼" },
  { name: "Palestinian Territories", flag: "🇵🇸" },
  { name: "Panama", flag: "🇵🇦" },
  { name: "Papua New Guinea", flag: "🇵🇬" },
  { name: "Paraguay", flag: "🇵🇾" },
  { name: "Peru", flag: "🇵🇪" },
  { name: "Philippines", flag: "🇵🇭" },
  { name: "Pitcairn Islands", flag: "🇵🇳" },
  { name: "Poland", flag: "🇵🇱" },
  { name: "Portugal", flag: "🇵🇹" },
  { name: "Puerto Rico", flag: "🇵🇷" },
  { name: "Qatar", flag: "🇶🇦" },
  { name: "Réunion", flag: "🇷🇪" },
  { name: "Romania", flag: "🇷🇴" },
  { name: "Russia", flag: "🇷🇺" },
  { name: "Rwanda", flag: "🇷🇼" },
  { name: "Samoa", flag: "🇼🇸" },
  { name: "San Marino", flag: "🇸🇲" },
  { name: "São Tomé & Príncipe", flag: "🇸🇹" },
  { name: "Saudi Arabia", flag: "🇸🇦" },
  { name: "Scotland", flag: "🏴󠁧󠁢󠁳󠁣󠁴󠁿" },
  { name: "Senegal", flag: "🇸🇳" },
  { name: "Serbia", flag: "🇷🇸" },
  { name: "Seychelles", flag: "🇸🇨" },
  { name: "Sierra Leone", flag: "🇸🇱" },
  { name: "Singapore", flag: "🇸🇬" },
  { name: "Sint Maarten", flag: "🇸🇽" },
  { name: "Slovakia", flag: "🇸🇰" },
  { name: "Slovenia", flag: "🇸🇮" },
  { name: "Solomon Islands", flag: "🇸🇧" },
  { name: "Somalia", flag: "🇸🇴" },
  { name: "South Africa", flag: "🇿🇦" },
  { name: "South Georgia & South Sandwich Islands", flag: "🇬🇸" },
  { name: "South Korea", flag: "🇰🇷" },
  { name: "South Sudan", flag: "🇸🇸" },
  { name: "Spain", flag: "🇪🇸" },
  { name: "Sri Lanka", flag: "🇱🇰" },
  { name: "St. Barthélemy", flag: "🇧🇱" },
  { name: "St. Helena", flag: "🇸🇭" },
  { name: "St. Kitts & Nevis", flag: "🇰🇳" },
  { name: "St. Lucia", flag: "🇱🇨" },
  { name: "St. Martin", flag: "🇲🇫" },
  { name: "St. Pierre & Miquelon", flag: "🇵🇲" },
  { name: "St. Vincent & Grenadines", flag: "🇻🇨" },
  { name: "Sudan", flag: "🇸🇩" },
  { name: "Suriname", flag: "🇸🇷" },
  { name: "Svalbard & Jan Mayen", flag: "🇸🇯" },
  { name: "Sweden", flag: "🇸🇪" },
  { name: "Switzerland", flag: "🇨🇭" },
  { name: "Syria", flag: "🇸🇾" },
  { name: "Taiwan", flag: "🇹🇼" },
  { name: "Tajikistan", flag: "🇹🇯" },
  { name: "Tanzania", flag: "🇹🇿" },
  { name: "Thailand", flag: "🇹🇭" },
  { name: "Timor-Leste", flag: "🇹🇱" },
  { name: "Togo", flag: "🇹🇬" },
  { name: "Tokelau", flag: "🇹🇰" },
  { name: "Tonga", flag: "🇹🇴" },
  { name: "Trinidad & Tobago", flag: "🇹🇹" },
  { name: "Tristan Da Cunha", flag: "🇹🇦" },
  { name: "Tunisia", flag: "🇹🇳" },
  { name: "Turkey", flag: "🇹🇷" },
  { name: "Turkmenistan", flag: "🇹🇲" },
  { name: "Turks & Caicos Islands", flag: "🇹🇨" },
  { name: "Tuvalu", flag: "🇹🇻" },
  { name: "U.S. Outlying Islands", flag: "🇺🇲" },
  { name: "U.S. Virgin Islands", flag: "🇻🇮" },
  { name: "Uganda", flag: "🇺🇬" },
  { name: "Ukraine", flag: "🇺🇦" },
  { short_name: "UAE", name: "United Arab Emirates", flag: "🇦🇪" },
  { short_name: "UK", name: "United Kingdom", flag: "🇬🇧" },
  { name: "United Nations", flag: "🇺🇳" },
  { short_name: "USA", name: "United States", flag: "🇺🇸" },
  { name: "Uruguay", flag: "🇺🇾" },
  { name: "Uzbekistan", flag: "🇺🇿" },
  { name: "Vanuatu", flag: "🇻🇺" },
  { name: "Vatican City", flag: "🇻🇦" },
  { name: "Venezuela", flag: "🇻🇪" },
  { name: "Vietnam", flag: "🇻🇳" },
  { name: "Wales", flag: "🏴󠁧󠁢󠁷󠁬󠁳󠁿" },
  { name: "Wallis & Futuna", flag: "🇼🇫" },
  { name: "Western Sahara", flag: "🇪🇭" },
  { name: "Yemen", flag: "🇾🇪" },
  { name: "Zambia", flag: "🇿🇲" },
  { name: "Zimbabwe", flag: "🇿🇼" },
];
